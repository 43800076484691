import type { Amount } from '@orus.eu/amount'
import type { PartnerApplicationFeeOneTime, Quote } from '../quote.js'

export type RcphDeductibles = {
  /** The deductibles applicable to the "RC Pro" and "Avantages plus" guarantees */
  rcp: {
    /** The deductible applicable to a material damage outside of US and Canada */
    materialDamage: Amount
    /** The deductible applicable to an immaterial damage outside of US and Canada */
    immaterialDamage: Amount
    /** The deductible applicable to a material or immaterial damage inside of US or Canada, only if world option enabled */
    materialOrImmaterialDamageUsCanada: Amount
    /** The deductible applicable to a bodily harm */
    bodilyHarm: Amount
    /** The deductible applicable to exercising the project cost guarantee */
    projectCostGuarantee: Amount
  }

  /** The deductibles applicable to the "RC Exploitation / RC Employeur" guarantees */
  rce: {
    /** The deductible applicable to a material or immaterial damage */
    materialOrImmaterialDamage: Amount
    /** The deductible applicable to a bodily harm */
    bodilyHarm: Amount
  }

  /** The deductible applicable to the "Protection Juridique" guarantee */
  pj: Amount

  /** The deductible applicable to TDMI */
  tdmi: Amount

  /** The deductible applicable to cyber */
  cyber: Amount
}

export type RcphQuoteYearlyTaxName = 'terrorism'
export type RcphQuoteGuaranteeName = 'rcp' | 'pj'

export const allTdmiGuarantees = ['tdmiAssets', 'tdmiCatnat', 'tdmiGareat'] as const
export type AllTdmiGuarantees = (typeof allTdmiGuarantees)[number]

export const allCyberGuarantees = [
  'cyberBase',
  'cyberFraudePiratage',
  'cyberDommagesPe',
  'cyberDefaillanceFournisseur',
  'cyberDommagesTier',
] as const

export type AllCyberGuarantees = (typeof allCyberGuarantees)[number]

export const allMrpSmallOfficeGuarantees = [
  'mrpSmallOfficeContent',
  'mrpSmallOfficeRcocc',
  'mrpSmallOfficeRvt',
  'mrpSmallOfficeCatnat',
  'mrpSmallOfficeGareat',
  'mrpSmallOfficeAssistance',
] as const
export type AllMrpSmallOfficeGuarantees = (typeof allMrpSmallOfficeGuarantees)[number]

export type RcphQuoteOptionName = 'world' | 'rce' | AllTdmiGuarantees | AllCyberGuarantees | AllMrpSmallOfficeGuarantees

export type RcphQuoteV2 = Quote<
  RcphDeductibles,
  RcphQuoteYearlyTaxName,
  RcphQuoteGuaranteeName,
  RcphQuoteOptionName,
  PartnerApplicationFeeOneTime,
  void
>
